import { Button } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import { CDN_BASE_URL } from '@src/config';
import Mission from '@src/stores/Missions/Mission';
import MissionPaymentCycle, {
  PaymentCycleDisplayStatus,
} from '@src/stores/Missions/MissionPaymentCycle';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import useGetMonthlyRetainerDataForCurrentUserRole from '../hooks/useGetMonthlyRetainerDataForCurrentUserRole';
import useGetPaymentAndHoursForGivenPeriod from '../hooks/useGetPaymentAndHoursForGivenPeriod';
import PaymentAccountTemporarilySuspendedModal from '@src/components/Modal/PaymentAccountTemporarilySuspendedModal';
import { Icon } from '@a_team/ui-components';

interface PaymentCycleProps {
  paymentCycle: MissionPaymentCycle;
  selected: boolean;
  onClick: () => void;
  adminView?: boolean;
  isActiveRole?: boolean;
  mission: Mission;
  onSubmitClick: () => void;
}

const useStyles = createUseStyles<{
  selected: boolean;
  isToSubmit: boolean;
  showSubmitButton: boolean;
}>({
  container: {
    border: ({ selected }) =>
      selected ? '2px solid #6D00D7' : '1px solid #DADADC',
    width: 260,
    color: '#222',
    borderRadius: 8,
    cursor: ({ selected }) => (selected ? 'default' : 'pointer'),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    padding: ({ showSubmitButton }) => (showSubmitButton ? 13 : 16),
    gap: 16,
    background: ({ isToSubmit }) => (isToSubmit ? '#FFF4FB' : '#FCFAFF'),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  infoContainer: {
    padding: 16,
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  dateRange: {
    fontSize: 14,
    fontWeight: 400,
  },
  time: {
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  line: {
    display: 'inline-block',
    width: 1,
    height: 16,
    background: '#DADADC',
    margin: '0 8px',
  },
  statusIcon: {
    height: 24,
    width: 24,
  },
  verticalLine: {
    display: 'inline-block',
    width: 2,
    height: 24,
    background: '#6D00D7',
    marginRight: 8,
  },
  submitButton: {
    padding: '8px !important',
    marginLeft: 'auto !important',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 4,
    },
  },
  rateLabel: {
    color: '#62646A',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      width: 320,
    },
  },
});

const PaymentCycle = ({
  paymentCycle,
  selected,
  onClick,
  adminView,
  isActiveRole,
  mission,
  onSubmitClick,
}: PaymentCycleProps): JSX.Element => {
  const displayStatus = paymentCycle.displayStatus;
  const [
    showPaymentAccountSuspendedModal,
    setShowPaymentAccountSuspendedModal,
  ] = useState(false);

  const showSubmitButton =
    !adminView &&
    !mission?.isMissionManager &&
    !!paymentCycle?.paymentNotificationShown &&
    !mission?.isPaymentSetupNoticeShown &&
    !!isActiveRole;

  const showPaymentAccountSuspendedModalTriggerButton =
    !showSubmitButton &&
    !adminView &&
    !mission?.isMissionManager &&
    !!paymentCycle?.paymentNotificationShown &&
    mission?.isPaymentAccountSuspended &&
    !!isActiveRole;

  const styles = useStyles({
    selected,
    isToSubmit: displayStatus === PaymentCycleDisplayStatus.Submit,
    showSubmitButton,
  });

  const getStatus = () => {
    switch (displayStatus) {
      case PaymentCycleDisplayStatus.Active:
        return 'Active';
      case PaymentCycleDisplayStatus.Submit:
        return 'To Submit';
      case PaymentCycleDisplayStatus.Submitted:
        return 'Submitted';
      case PaymentCycleDisplayStatus.Invoiced:
        return 'Invoiced';
      case PaymentCycleDisplayStatus.Paid:
        return 'Paid Out';
      default:
        return 'Active';
    }
  };

  const getStatusIcon = () => {
    switch (displayStatus) {
      case PaymentCycleDisplayStatus.Active:
        return `${CDN_BASE_URL}/platform/active-timesheet.svg`;
      case PaymentCycleDisplayStatus.Submit:
        return `${CDN_BASE_URL}/platform/to-submit-timesheet.svg`;
      case PaymentCycleDisplayStatus.Submitted:
        return `${CDN_BASE_URL}/platform/submitted-timesheet.svg`;
      case PaymentCycleDisplayStatus.Invoiced:
        return `${CDN_BASE_URL}/platform/invoiced-timesheet.svg`;
      case PaymentCycleDisplayStatus.Paid:
        return `${CDN_BASE_URL}/platform/paid-out-timesheet.svg`;
      default:
        return `${CDN_BASE_URL}/platform/active-timesheet.svg`;
    }
  };

  const { isFullTimeRetainer } = useGetMonthlyRetainerDataForCurrentUserRole();

  const { fixedHoursAmount, fixedPaymentAmount } =
    useGetPaymentAndHoursForGivenPeriod(
      paymentCycle.data?.startDate,
      paymentCycle.data?.endDate,
    );

  const status = getStatus();
  const statusIcon = getStatusIcon();
  const rateType = isFullTimeRetainer ? 'Monthly rate' : 'Hourly rate';

  const date = `${paymentCycle.formattedStartDate} - ${paymentCycle.formattedEndDate}, ${paymentCycle.formattedYear}`;

  return (
    <div
      onClick={onClick}
      className={styles.container}
      data-testing-id="timesheets-payment-cycle-card"
    >
      <div className={styles.status}>
        <div>
          {selected && <div className={styles.verticalLine} />}
          <img className={styles.statusIcon} alt={status} src={statusIcon} />
        </div>
        {status}

        {showSubmitButton && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onSubmitClick();
            }}
            className={styles.submitButton}
          >
            Submit now
          </Button>
        )}

        {showPaymentAccountSuspendedModalTriggerButton && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowPaymentAccountSuspendedModal(true);
            }}
            className={styles.submitButton}
          >
            <Icon
              size="md"
              name="lock"
              style={{
                color: '#FFFFFF',
              }}
            />
            Submit
          </Button>
        )}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.dateRange}>{date}</div>
        <div className={styles.time}>
          {isFullTimeRetainer
            ? fixedHoursAmount
            : paymentCycle.formattedTotalHoursToHHMM}
          h <span className={styles.line} />{' '}
          <span data-testing-id="timesheets-payment-cycle-card-total">
            {isFullTimeRetainer
              ? fixedPaymentAmount
              : paymentCycle.formattedTotalPayments}
          </span>
          <span className={styles.line} />
          <span className={styles.rateLabel}>{rateType}</span>
        </div>
      </div>

      {showPaymentAccountSuspendedModalTriggerButton && (
        <PaymentAccountTemporarilySuspendedModal
          open={showPaymentAccountSuspendedModal}
          onClose={() => setShowPaymentAccountSuspendedModal(false)}
        />
      )}
    </div>
  );
};

export default observer(PaymentCycle);
