import React, { ChangeEvent, useMemo } from 'react';
import {
  BorderColors,
  BorderRadius,
  Breakpoints,
  FontWeights,
  Icon,
  IconType,
  Margins,
  Spacing,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import TooltipWrapped from '@src/components/TooltipWrapped';

interface RatePanelProps {
  type: 'hourly' | 'monthly';
  hoursPerWeek: number;
  value: number;
  budget: {
    showBudget: boolean;
    budgetMin?: number;
    budgetMax?: number;
  };
  copy: string;
  guideLink: string;
  onChange: (rate: number) => void;
  disabled: boolean;
  hasFullAvailability?: boolean;
  error?: boolean;
}

const useStyles = createUseStyles({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 24,
    borderRadius: 16,
    border: '1px solid #DADADC',
    width: '100%',
    position: 'relative',
  },
  disabledOverlay: {
    position: 'absolute',
    width: '95%',
    height: '90%',
    top: 5,
    left: 5,
    background: 'rgba(255,255, 255, 0.8)',
    borderRadius: 8,
    zIndex: 2,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  icon: {
    color: '#222',
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    margin: 0,
  },
  copyRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  subHead: {
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
  },
  copy: {
    fontSize: 15,
    fontWeight: 400,
    color: '#62646A',
    margin: 0,
  },
  link: {
    color: '#6D00D7',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    },
  },
  budgetRow: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F9FAFC',
    padding: 16,
    borderRadius: 8,
    gap: 8,
    fontSize: 14,
  },
  rateRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  rateInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  rateInput: {
    position: 'relative',
    margin: Margins.none,
    zIndex: 1,
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 9,
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 9,
  },
  input: {
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    padding: '8px 30px 8px 26px',
    width: 140,
    fontWeight: FontWeights.regular,

    '&.error': {
      borderColor: '#F63041',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    titleRow: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    disabledOverlay: {
      height: '95%',
    },
    rateRow: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});

const RatePanel = (props: RatePanelProps) => {
  const {
    value,
    type,
    budget,
    hoursPerWeek,
    copy,
    guideLink,
    disabled,
    onChange,
  } = props;
  const styles = useStyles();

  const canEdit = useMemo(() => {
    if (type === 'hourly') {
      return !disabled;
    }

    return !disabled && props.hasFullAvailability;
  }, [disabled, props.hasFullAvailability, type]);

  const handleRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    let rate = Number(e.target.value);

    if (Number.isNaN(rate)) {
      rate = 0;
    }

    onChange(rate);
  };

  return (
    <div className={styles.panel}>
      {disabled && <div className={styles.disabledOverlay} />}
      <div className={styles.titleRow}>
        <Icon
          className={styles.icon}
          type={type === 'hourly' ? IconType.Clock : IconType.Calendar}
        />
        <h4 className={styles.title}>
          {type === 'hourly' ? 'Hourly role' : 'Monthly role'}
        </h4>
      </div>
      <div className={styles.copyRow}>
        {hoursPerWeek > 0 && (
          <h4 className={styles.subHead}>
            Approximately {hoursPerWeek} hours per week
          </h4>
        )}
        <p className={styles.copy}>
          {copy}{' '}
          <a
            href={guideLink}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </p>
      </div>
      {budget.showBudget && (
        <div className={styles.budgetRow}>
          Company's budget is ${budget.budgetMin?.toLocaleString()} to $
          {budget.budgetMax?.toLocaleString()}/{type === 'monthly' ? 'm' : 'hr'}
        </div>
      )}
      <div className={styles.rateRow}>
        <h4 className={styles.subHead}>My {type} rate starts at</h4>
        <TooltipWrapped
          arrow
          title={'To add a monthly rate, update availability to 40h/week'}
          disabled={canEdit}
        >
          <div className={styles.rateInputWrapper}>
            <div className={styles.rateInput}>
              <span className={styles.prefix}>$</span>
              <TextInput
                data-testing-id={'mission-application-min-rate-text-input'}
                disabled={!canEdit}
                variant="dashed"
                placeholder={'XXX'}
                className={cx(styles.input, {
                  error: !disabled && props.error,
                })}
                value={value}
                onChange={handleRateChange}
              />
              <span className={styles.suffix}>
                /{type === 'monthly' ? 'm' : 'hr'}
              </span>
            </div>
            <span>or above</span>
          </div>
        </TooltipWrapped>
      </div>
    </div>
  );
};

export default RatePanel;
