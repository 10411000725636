import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { apiUser, apiUsers } from '@ateams/api';
import { useStores } from '@src/stores';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';
import AuthStore from '@src/stores/Auth';
import { refreshProfile } from './utils';
import {
  PreferenceKey,
  PreferenceValue,
} from '@a_team/models/dist/UserPreferences';

export const useEvaluateFeatureFlag = ({
  uid,
  featureFlag,
}: {
  uid: UserId;
  featureFlag: FeatureFlagNames;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.users.evaluateFeatureFlag(uid, featureFlag),
    queryFn: async () => {
      return await apiUsers.evaluateFeatureFlag(auth, uid, featureFlag);
    },
  });
};

export const useRequestProfileReview = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.requestProfileReview(stores.auth);
    },
    onSuccess(data) {
      if (data.onboardingStage) {
        stores.auth.updateOnboardingStage(data.onboardingStage);
      }
    },
  });
};

export const useRequestEvaluationCall = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async (roleId: string) => {
      return apiUser.requestEvaluationCall(stores.auth, { roleId });
    },
    onSuccess(data) {
      if (data.evaluationFormUrl) {
        window.location.href = data.evaluationFormUrl;
      }
    },
  });
};

export const useGetUserRolesWithStatus = ({
  enabled,
}: { enabled?: boolean } = {}) => {
  const stores = useStores();

  return useQuery({
    queryKey: queryKeys.users.userRolesWithStatus(stores.auth.uid ?? ''),
    queryFn: async () => {
      return apiUser.getUserRolesWithStatus(stores.auth);
    },
    enabled,
  });
};

export const useGetEvaluationCallDetails = ({
  enabled,
}: { enabled?: boolean } = {}) => {
  const stores = useStores();

  return useQuery({
    queryKey: queryKeys.users.evaluationCallDetails(stores.auth.uid ?? ''),
    queryFn: async () => {
      return apiUser.getEvaluationCallDetails(stores.auth);
    },
    enabled,
  });
};

export const useConfirmUpdatesSubmission = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async () => {
      return await apiUser.confirmUpdatesSubmission(stores.auth);
    },
    onSuccess: async (data) => {
      if (data?.onboardingStage) {
        stores.auth.updateOnboardingStage(data.onboardingStage);
        await refreshProfile(stores);
      }
    },
  });
};

const fetchUsersByIds =
  (auth: AuthStore, uids: UserId[]) => async (): Promise<BasicUserObject[]> => {
    return uids.length > 0 ? apiUsers.adminGetUsersByIds(auth, uids) : [];
  };

export const useGetUsersByIds = (auth: AuthStore, uids: UserId[]) => {
  return useQuery(
    queryKeys.users.usersByIds(uids),
    fetchUsersByIds(auth, uids),
  );
};

export const useDismissWelcomePage = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.dismissWelcomePage(auth);
    },
    onSuccess: async () => {
      auth.dismissWelcomePage();
    },
  });
};

export const useGetProfileCompletion = ({ enabled }: { enabled: boolean }) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.users.profileCompletion(auth.uid ?? ''),
    queryFn: async () => {
      return apiUser.getProfileCompletion(auth);
    },
    enabled,
  });
};

export const useUpdateOnboardingStage = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.updateOnboardingStage(auth);
    },
    onSuccess: async (data) => {
      if (data.onboardingStage) {
        auth.updateOnboardingStage(data.onboardingStage);
      }
    },
  });
};

export const useGetUserPreferences = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.users.userPreferences(auth.uid ?? ''),
    queryFn: async () => {
      return apiUser.getUserPreferences(auth);
    },
  });
};

export const useUpdateUserPreference = () => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      key: PreferenceKey;
      value: PreferenceValue<PreferenceKey>;
    }) => {
      return apiUser.updateUserPreference(auth, data);
    },
    // refetch user preferences after mutation
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.users.userPreferences(auth.uid ?? ''),
      });
    },
  });
};

export const useGetLastResumeParsed = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.users.lastResumeParsed(auth.uid ?? ''),
    queryFn: async () => {
      return apiUser.getLastResumeParsed(auth);
    },
  });
};
