import { AccountId } from './Account';
import { CompanyId, EnrichableCompany } from './Company';
import { UserId } from './UserObject';

export type FeatureFlagId = string;
export type UserFeatureFlagObject = {
  user: UserId;
  isOpen: boolean;
};

/** @deprecated use companies or accounts instead */
export type ClientCompanyFeatureFlagObject = {
  companyId: CompanyId;
  isOpen: boolean;
};

export type EnrichableCompanyFeatureFlagObject = {
  companyId: EnrichableCompany['cid'];
  isOpen: boolean;
};

export type AccountFeatureFlagObject = {
  accountId: AccountId;
  isOpen: boolean;
};

export type FeatureFlagObject = {
  fid: FeatureFlagId;
  name: string;
  description?: string;
  isOpen: boolean;
  users: UserFeatureFlagObject[];
  /** @deprecated use companies or accounts instead */
  clientCompanies?: ClientCompanyFeatureFlagObject[];
  companies?: EnrichableCompanyFeatureFlagObject[];
  accounts?: AccountFeatureFlagObject[];
};

export type UserFeatureFlag = Pick<
  FeatureFlagObject,
  'name' | 'description' | 'isOpen'
>;

export enum FeatureFlagNames {
  ScheduledToEnd = 'ScheduledToEnd',
  WorkingHours = 'WorkingHours',
  CompaniesInTargeter = 'CompaniesInTargeter',
  Rewards = 'Rewards',
  TFSProposalBlurb = 'tfsProposalBlurb',
  NewOnboardingBannerWithLongProcessingTimes = 'NewOnboardingBannerWithLongProcessingTimes',
  WhatsNew = 'WhatsNew',
  ProfilePortfolio = 'ProfilePortfolio',
  Hubspot = 'Hubspot',
  AStore = 'AStore',
  MissionApplicationStatusV2 = 'MissionApplicationStatusV2',
  MissionApplicationAnalysis = 'MissionApplicationAnalysis',
  BuilderLikes = 'BuilderLikes',
  WhatsNewBanner = 'WhatsNewBanner',
  NewEvaluation = 'NewEvaluation',
  ProjectDescriptionAssistant = 'ProjectDescriptionAssistant',
  TeamUp = 'TeamUp',
  ClientUserMgmt = 'ClientUserMgmt',
  ApplicationPreSave = 'ApplicationPreSave',
  SkipTeamUp = 'SkipTeamUp',
  ProfileImageUploader = 'ProfileImageUploader',
  PitchWriterAssistant = 'PitchWriterAssistant',
  ApplicationBuilderScoring = 'ApplicationBuilderScoring',
  CompanyQuestionRecommendation = 'CompanyQuestionRecommendation',
  BuilderInterviewScheduling = 'BuilderInterviewScheduling',
  TeamPulseNewQuestions = 'TeamPulseNewQuestions',
  ProfileCompleteness = 'ProfileCompleteness',
  ViewPastApplications = 'ViewPastApplications',
  NewTimesheets = 'NewTimesheets',
  EnrichedCompaniesLinkedToExperiences = 'EnrichedCompaniesLinkedToExperiences',
  AiChatCta = 'AiChatCta',
  NewCalendarLinkingFlow = 'NewCalendarLinkingFlow',
  GenerateAboutMeSuggestionOnDemand = 'GenerateAboutMeSuggestionOnDemand',
  MissionMonthlyRates = 'MissionMonthlyRates',
  RoleKeywords = 'RoleKeywords',
  NewProjectExperienceFields = 'NewProjectExperienceFields',
  SplittingProfileAndApplication = 'SplittingProfileAndApplication',
  NewJobs = 'NewJobs',
  MultipleIndustriesForExperiences = 'MultipleIndustriesForExperiences',
  MonthlyRetainerInvoicing = 'MonthlyRetainerInvoicing',
  LimitedAccess = 'LimitedAccess',
  RelatedExperiences = 'RelatedExperiences',
  RequestExtension = 'RequestExtension',
  SharedCalendarFLow = 'SharedCalendarFLow',
  NewMissionControlAllMissionsSorting = 'NewMissionControlAllMissionsSorting',
  LocationMismatch = 'LocationMismatch',
  NewEmailTemplate = 'NewEmailTemplate',
  ApplicationStatusNotification = 'ApplicationStatusNotification',
  AutomatedReviewStatuses = 'AutomatedReviewStatuses',
  MultipleMissionsCancelledEmail = 'MultipleMissionsCancelledEmail',
  CheckoutCreditCards = 'CheckoutCreditCards',
  CheckoutACH = 'CheckoutACH',
  UseUserService = 'UseUserService',
  CheckoutV1 = 'CheckoutV1',
  ProfileSuggestionsResume = 'ProfileSuggestionsResume',
  DirectInterviewBooking = 'DirectInterviewBooking',
  showProposalRatesInClientApp = 'showProposalRatesInClientApp',
  ZeroFrictionSignUp = 'ZeroFrictionSignUp',
  OptimizeDatabaseOperations = 'OptimizeDatabaseOperations',
  SaveSkillsRightAwayOnApplication = 'SaveSkillsRightAwayOnApplication',
  SaveApplicationProgress = 'SaveApplicationProgress',
  BuilderRatings = 'BuilderRatings',
  CustomRoleMarkups = 'CustomRoleMarkups', // If true, reveals a "Role markup" field in the role settings: https://www.loom.com/share/98561273af6a4309bfaf2274289dfec7
  EnhanceProfileForUserV2 = 'EnhanceProfileForUserV2',
  SaveRolesRightAwayOnApplication = 'SaveRolesRightAwayOnApplication',
  SaveApplicationProgressProjects = 'SaveApplicationProgressProjects',
  TeamSummaryGeneration = 'TeamSummaryGeneration',
  HideInvoiceMarkups = 'HideInvoiceMarkups', // If true, hides margins/markups on invoices for builders and clients
  InvitePage = 'InvitePage',
  OnboardingV2 = 'OnboardingV2',
  SkillsSelectorInline = 'SkillsSelectorInline',
  WorkspaceInvoices = 'WorkspaceInvoices', // If true, enables invoice generation for aggregating multiple missions on a workspace
  StructuredDataProjects = 'StructuredDataProjects',
  RemoveFeatureSkillOption = 'RemoveFeatureSkillOption',
  NewCalendarLoginFlow = 'NewCalendarLoginFlow',
  NewEvaluationFeedbackForm = 'NewEvaluationFeedbackForm',
  NewRoleExpertiseScore = 'NewRoleExpertiseScore',
  SuggestedProjectsOnApplication = 'SuggestedProjectsOnApplication',
  GenerateAboutMeSuggestionOnApplication = 'GenerateAboutMeSuggestionOnApplication',
  PaymentMethod = 'PaymentMethod',
  SuggestProjectsFromURLOrPDF = 'SuggestProjectsFromURLOrPDF',
  MarkBuilderAsAcceptedAfterAllScores = 'MarkBuilderAsAcceptedAfterAllScores',
  GenerateProfileSuggestionsBasedOnCallTranscriptions = 'GenerateProfileSuggestionsBasedOnCallTranscriptions',
  NewCalendarFlow = 'NewCalendarFlow',
  MissionControlOptimization = 'MissionControlOptimization',
  ContractsV2 = 'ContractsV2',
  RoleDescriptionRichText = 'RoleDescriptionRichText',
  TeamSummaryEmail = 'TeamSummaryEmail',
  LogoOnNav = 'LogoOnNav',
  // for timesheet auto-submission deadline
  LowerTimesheetSubmissionDeadline = 'LowerTimesheetSubmissionDeadline',
  RolePaymentTermSwitching = 'RolePaymentTermSwitching',
  ResumeOnApplication = 'ResumeOnApplication',
  NewRatesOnApplication = 'NewRatesOnApplication',
}
