import { UserId } from './UserObject';

export enum PreferenceKey {
  DisableSuggestionsTimeLimit = 'disableSuggestionsTimeLimit',
  HasAcceptedYouAreIn = 'hasAcceptedYouAreIn',
}

export interface UserPreferencesObject {
  userId: UserId;
  preferences: {
    [PreferenceKey.DisableSuggestionsTimeLimit]?: boolean;
    [PreferenceKey.HasAcceptedYouAreIn]?: boolean;
  };
}

export type PreferenceValue<K extends PreferenceKey> =
  UserPreferencesObject['preferences'][K];
