import React, { ReactElement, useEffect, useMemo } from 'react';
import { groupBy } from 'lodash';
import AdminNotesObject, {
  AdminNotesMission,
  TimesheetsSummary,
} from '@a_team/models/dist/AdminNotesObject';
import MissionItem from './MissionItem';
import { Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import {
  stringifyDateRangeAdvanced,
  stringifyMinutes,
} from '@src/helpers/time';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

interface MissionTabProps {
  missions: AdminNotesObject['missions'];
  setRolePerformanceFlag?: (newMission: AdminNotesMission) => Promise<void>;
  latestTimesheetsSummaries?: AdminNotesObject['latestTimesheetsSummaries'];
}

interface TimePeriodProps {
  isLast: boolean;
  timesheetsSummary: TimesheetsSummary;
}

const THIRD = `${100 / 3}%`;
const SEPARATOR_STYLE = `1px solid ${Colors.regular}`;

const useStyles = createUseStyles({
  timePeriod: { display: 'flex', justifyContent: 'space-between' },
  timePeriodHours: ({ isLast = false }: Partial<TimePeriodProps>) => ({
    fontWeight: 'bold',
    paddingRight: isLast ? 0 : 10,
    marginRight: isLast ? 0 : 10,
    borderRight: isLast ? '' : SEPARATOR_STYLE,
  }),
  timePeriodsContainer: {
    display: 'grid',
    borderRadius: 8,
    gridTemplateColumns: `${THIRD} ${THIRD} ${THIRD}`,
    border: '1px solid #D9D9D9',
    padding: 10,
  },
  timePeriodsTitle: { marginBottom: 10, fontSize: 20, fontWeight: 600 },
  missionsGroup: {
    paddingBottom: 20,
  },
  missionsGroupTitle: {
    marginTop: 20,
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 500,
  },
});

export default function MissionsTab({
  missions,
  latestTimesheetsSummaries,
  setRolePerformanceFlag,
}: MissionTabProps): ReactElement {
  const styles = useStyles({});

  const groupedMissions = groupBy(missions, (mission) => {
    // treat `Active` and `ScheduledToSwitch` in the similar group as switch is transitionary.
    return mission.roleStatus === MissionRoleStatus.ScheduledToSwitch
      ? MissionRoleStatus.Active
      : mission.roleStatus;
  });
  const orderedRoles = Object.keys(MissionRoleStatus).filter(
    (roleStatus) => groupedMissions[roleStatus],
  );

  const location = useLocation();
  const history = useHistory();

  const query = useMemo(
    () => qs.parse(location.search.slice(1)),
    [location.search],
  );

  useEffect(() => {
    const searchParams = qs.stringify({
      ...query,
      timesheetSummaryRoleId: undefined,
    });
    history.push({
      pathname: location.pathname,
      search: searchParams ? `?${searchParams}` : '',
    });
  }, [query]);

  const timesheetSummaryModalOpenRoleId = query.timesheetSummaryRoleId;

  const getMissionGroupBorderStyling = (index: number) => ({
    borderBottom: index === orderedRoles.length - 1 ? '' : SEPARATOR_STYLE,
  });

  return (
    <>
      <MissionTabTitle latestTimesheetsSummaries={latestTimesheetsSummaries} />
      {orderedRoles.map((roleStatus, index) => (
        <div
          className={styles.missionsGroup}
          style={getMissionGroupBorderStyling(index)}
        >
          <div className={styles.missionsGroupTitle}>{roleStatus} Roles</div>
          <ul style={{ padding: 0 }}>
            {groupedMissions[roleStatus].map((mission) => {
              const timesheetModalOpenDefault =
                timesheetSummaryModalOpenRoleId === mission.rid;
              return (
                <MissionItem
                  timesheetModalOpenDefault={timesheetModalOpenDefault}
                  data={mission}
                  setRolePerformanceFlag={setRolePerformanceFlag}
                  key={mission.mid}
                />
              );
            })}
          </ul>
        </div>
      ))}
    </>
  );
}

const TimePeriod = ({
  timesheetsSummary,
  isLast,
}: TimePeriodProps): ReactElement => {
  const styles = useStyles({ isLast });

  return (
    <div className={styles.timePeriod}>
      {stringifyDateRangeAdvanced(
        timesheetsSummary.startDate,
        timesheetsSummary.endDate,
      )}
      <span className={styles.timePeriodHours}>
        {stringifyMinutes(timesheetsSummary.totalMinutes)}
      </span>
    </div>
  );
};

const MissionTabTitle = ({
  latestTimesheetsSummaries,
}: {
  latestTimesheetsSummaries?: AdminNotesObject['latestTimesheetsSummaries'];
}): ReactElement => {
  const styles = useStyles({});

  if (!latestTimesheetsSummaries) {
    return <></>;
  }

  return (
    <>
      <h1>Missions</h1>
      <div className={styles.timePeriodsTitle}>Last 3 time period hours</div>
      <div className={styles.timePeriodsContainer}>
        {latestTimesheetsSummaries.map((timesheetsSummary, index) => (
          <TimePeriod
            key={index}
            timesheetsSummary={timesheetsSummary}
            isLast={index === latestTimesheetsSummaries.length - 1}
          />
        ))}
      </div>
    </>
  );
};
