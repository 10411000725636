import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useScreenClass } from 'react-grid-system';
import Section from '../partials/Section';
import { GuidanceTooltip } from '../partials/Section/GuidanceTooltip/GuidanceTooltip';
import { TooltipContent } from '../partials/Section/TooltipContent';
import { Breakpoints, IconType, Spacing } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import Profile from '@src/stores/Profile/Profile';
import MissionRole from '@a_team/models/dist/MissionRole';
import useToggle from '@src/hooks/useToggle';
import { Checkbox } from '@a_team/ui-components';
import RatePanel from './RatePanel';
import { GuidanceMessage } from '../partials/Section/GuidanceMessage';

interface MissionRoleRatesProps {
  profile?: Profile;
  currentRole?: MissionRole;
  readonly?: boolean;
  error?: boolean;
}

const useStyles = createUseStyles({
  rateSection: {
    marginBottom: Spacing.xxxLarge,
  },
  consentToggle: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F9FAFC',
    padding: 16,
    borderRadius: 8,
    gap: 8,
  },
  checkbox: {
    '& span + span': {
      userSelect: 'none',
    },
    '& svg': {
      marginBottom: 3,
    },
  },
  rateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    margin: '24px 0 12px',
  },
  error: {
    color: '#F63041',
    paddingLeft: 3,
    marginBottom: 12,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    checkbox: {
      '& svg': {
        marginBottom: 5,
      },
    },
    rateContainer: {
      flexDirection: 'row',
    },
  },
});

const MissionRoleRatesV2 = (props: MissionRoleRatesProps) => {
  const { profile, currentRole, readonly, error } = props;
  const styles = useStyles();
  const screenClass = useScreenClass();
  const [hasConsented, toggleHasConsented] = useToggle(
    (profile?.application?.monthlyRateRangeMin ?? 0) > 0 ||
      (profile?.application?.minHourlyRate ?? 0) > 0,
  );

  const monthlyRate = useMemo(() => {
    const builderDefaultMinRate = profile?.minimumMonthlyRate ?? 0;
    const builderApplicationMinRate =
      profile?.application?.monthlyRateRangeMin ?? 0;

    if (builderApplicationMinRate > 0) {
      return builderApplicationMinRate;
    }

    return builderDefaultMinRate > 0 ? builderDefaultMinRate : '';
  }, [profile?.minimumMonthlyRate, profile?.application?.monthlyRateRangeMin]);

  const hasFullAvailability = useMemo(() => {
    if (!profile?.availability?.weeklyHoursAvailable) {
      return false;
    }

    return profile.availability.weeklyHoursAvailable >= 40;
  }, [profile?.availability?.weeklyHoursAvailable]);

  useEffect(() => {
    if (!hasFullAvailability) {
      profile?.application?.removeMonthlyRateRange();
    }
  }, [hasFullAvailability]);

  useEffect(() => {
    if (!hasConsented) {
      profile?.application?.removeMonthlyRateRange();
      profile?.application?.removeHourlyRateRange();
    }
  }, [hasConsented]);

  const isHourlyRateAboveRange = useMemo(() => {
    if (!currentRole?.showRateRangeToBuilders) {
      return false;
    }

    if (!currentRole?.builderRateMax) {
      return false;
    }

    const minHourlyRate = profile?.application?.minHourlyRate ?? 0;
    const maxHourlyRate = profile?.application?.maxHourlyRate ?? 0;

    return (
      minHourlyRate > currentRole.builderRateMax &&
      maxHourlyRate > currentRole.builderRateMax
    );
  }, [
    profile?.application?.minHourlyRate,
    profile?.application?.maxHourlyRate,
    currentRole?.showRateRangeToBuilders,
    currentRole?.builderRateMax,
  ]);

  const isMonthlyRateAboveRange = useMemo(() => {
    if (!currentRole?.showRateRangeToBuilders) {
      return false;
    }

    if (!currentRole?.builderMonthlyRateMax) {
      return false;
    }

    return (
      Math.round(Number(monthlyRate)) >
      Math.round(currentRole.builderMonthlyRateMax)
    );
  }, [
    monthlyRate,
    currentRole?.showRateRangeToBuilders,
    currentRole?.builderMonthlyRateMax,
  ]);

  const tooltipText = useMemo(() => {
    if (screenClass === 'xs') return undefined;
    let tooltip =
      'Setting a top and bottom rate makes it easier to connect with more companies who are at different stages of growth.\n' +
      '\n' +
      'The rate that you set here is your take-home rate. The client will pay rate that is 20% higher rate which means A.Team will collect 16.67% of the total payout. For example, if you set the rate to $100 the client will pay $120 and A.Team will collect $20 as fees';
    error && (tooltip = 'Please enter a minimum and maximum rate');
    return tooltip;
  }, [error, screenClass]);

  const hourlyBudget = useMemo(() => {
    if (
      readonly ||
      !currentRole?.showRateRangeToBuilders ||
      !currentRole.builderRateMin ||
      !currentRole.builderRateMax
    ) {
      return { showBudget: false };
    }

    const budgetMin = Math.round(currentRole.builderRateMin);
    const budgetMax = Math.round(currentRole.builderRateMax);

    return {
      showBudget: true,
      budgetMin,
      budgetMax,
    };
  }, [
    readonly,
    currentRole?.showRateRangeToBuilders,
    currentRole?.builderRateMin,
    currentRole?.builderRateMax,
  ]);

  const monthlyBudget = useMemo(() => {
    if (
      readonly ||
      !currentRole?.showRateRangeToBuilders ||
      !currentRole.collectMonthlyRate
    ) {
      return { showBudget: false };
    }

    const budgetMin = Math.round(currentRole.builderMonthlyRateMin ?? 0);
    const budgetMax = Math.round(currentRole.builderMonthlyRateMax ?? 0);

    return {
      showBudget: true,
      budgetMin,
      budgetMax,
    };
  }, [
    readonly,
    currentRole?.showRateRangeToBuilders,
    currentRole?.collectMonthlyRate,
    currentRole?.builderMonthlyRateMin,
    currentRole?.builderMonthlyRateMax,
  ]);

  const guidanceCopy = useMemo(() => {
    const hourlyRate = Math.round(currentRole?.builderRateMax ?? 0);
    const monthlyRate = Math.round(
      currentRole?.builderMonthlyRateMax ?? 0,
    ).toLocaleString();

    if (isMonthlyRateAboveRange && isHourlyRateAboveRange) {
      return `Your hourly and monthly rates are above the preferred budget ($${hourlyRate}/hr and $${monthlyRate}/m), you can still apply, but your chances of being selected are lower.`;
    }

    if (isMonthlyRateAboveRange) {
      return `Your monthly rate is above the preferred budget ($${monthlyRate}/m), you can still apply, but your chances of being selected are lower.`;
    }

    if (isHourlyRateAboveRange) {
      return `Your hourly rate is above the preferred budget ($${hourlyRate}/hr), you can still apply, but your chances of being selected are lower.`;
    }

    return undefined;
  }, [isHourlyRateAboveRange, isMonthlyRateAboveRange]);

  const errorText = useMemo(() => {
    if (!hasConsented || readonly) {
      return undefined;
    }

    if (
      profile?.application?.hasHourlyRateError &&
      profile?.application?.hasMonthlyRateError
    ) {
      return 'You must specify your minimum preferred hourly and monthly rates.';
    }

    if (profile?.application?.hasHourlyRateError) {
      return 'You must specify your minimum preferred hourly rate.';
    }

    if (profile?.application?.hasMonthlyRateError) {
      return 'You must specify your minimum preferred monthly rate.';
    }

    return undefined;
  }, [
    hasConsented,
    readonly,
    profile?.application?.minHourlyRate,
    profile?.application?.monthlyRateRangeMin,
    currentRole?.collectMonthlyRate,
  ]);

  const handleHourlyRateChange = (rate: number) => {
    profile?.application?.setMinHourlyRate(rate);
    profile?.application?.setMaxHourlyRate(rate);
  };

  const handleMonthlyRateChange = (rate: number) =>
    profile?.application?.setMonthlyRateRange({
      min: rate,
      max: rate,
    });

  if (!profile || !profile.application) {
    return null;
  }

  return (
    <Section
      title="Rate"
      description={`Your rate should reflect your skills, experience, role requirements, and market demand. The company’s budget reflects the range the company has budgeted for the role.`}
      type="large"
      tooltipText={tooltipText}
      guidanceTooltip={
        !profile?.application?.readonly ? (
          <GuidanceTooltip label={TooltipContent.missionRoleRates.label}>
            {TooltipContent.missionRoleRates.component}
          </GuidanceTooltip>
        ) : undefined
      }
      className={styles.rateSection}
    >
      <div className={styles.consentToggle}>
        <Checkbox
          className={styles.checkbox}
          onChange={toggleHasConsented}
          label="If selected, I agree to work on this role at or above the rate entered below."
          checked={hasConsented}
        />
      </div>
      <div className={styles.rateContainer}>
        <RatePanel
          disabled={!hasConsented || !!readonly}
          type="hourly"
          hoursPerWeek={currentRole?.availability?.weeklyHoursAvailable ?? 0}
          copy={`Variable based on company needs, you’re paid for the hours worked.`}
          value={profile.application.minHourlyRate}
          error={profile.application.hasHourlyRateError}
          guideLink="https://guide.a.team/missions/choosing-your-rate"
          budget={hourlyBudget}
          onChange={handleHourlyRateChange}
        />
        {currentRole?.collectMonthlyRate && (
          <RatePanel
            disabled={!hasConsented || !!readonly}
            hasFullAvailability={hasFullAvailability}
            type="monthly"
            hoursPerWeek={40}
            value={profile.application.monthlyRateRangeMin}
            error={profile.application.hasMonthlyRateError}
            copy={`Guaranteed full-time work for 1+ month, usually longer, for a fixed retainer.`}
            guideLink="https://guide.a.team/missions/choosing-your-rate"
            budget={monthlyBudget}
            onChange={handleMonthlyRateChange}
          />
        )}
      </div>
      {errorText && <div className={styles.error}>x {errorText}</div>}
      {guidanceCopy && (
        <GuidanceMessage
          text={guidanceCopy}
          type={'secondary'}
          iconType={IconType.StarsPurple}
        />
      )}
    </Section>
  );
};

export default observer(MissionRoleRatesV2);
