import { Button, Icon } from '@a_team/ui-components';
import { TextColors } from '@ateams/components';
import { useResumeUpload } from '@src/hooks/useResumeUpload';
import { useStores } from '@src/stores';
import Profile from '@src/stores/Profile/Profile';
import Section from '@src/views/Profile/Main/partials/Section';
import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import ResumeItem from './ResumeItem';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { format } from 'date-fns';
import { useGetLastResumeParsed } from '@src/rq/users';

const useStyles = createUseStyles({
  optional: {
    fontSize: 14,
    color: TextColors.lighter,
  },
  description: {
    color: '#4C4D50',
    marginBottom: '24px',
  },
  button: {
    padding: '8px 12px !important',

    // select first span child
    '& > span:first-child': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  },
});

interface ResumeProps {
  profile: Profile;
}

const THREE_MONTHS_IN_MS = 90 * 24 * 60 * 60 * 1000;

const Resume = ({ profile }: ResumeProps): ReactElement => {
  const styles = useStyles();
  const { uiStore } = useStores();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { data: lastResumeParsed } = useGetLastResumeParsed();
  const [isUpToDateConfirmation, setIsUpToDateConfirmation] = useState(false);
  const lastUpdated =
    profile.userCV.cvUploadedAt ?? lastResumeParsed?.updatedAt;

  // Add these constants for better readability
  const isResumeOlderThanThreeMonths = lastUpdated
    ? new Date().getTime() - new Date(lastUpdated).getTime() >
      THREE_MONTHS_IN_MS
    : false;

  const { handleFileSelected, uploading, handleUpload } = useResumeUpload({
    onUpload(downloadUrl) {
      uiStore.setUserCv({
        label: profile.userCV.label,
        downloadUrl,
      });
      profile.updateUserCv(downloadUrl);
      setShowSuccessMessage(true);
    },
  });

  const handleResumeUpload = async () => {
    // Create an input element
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';

    // Handle file selection
    input.onchange = async (e) => {
      const target = e.target as HTMLInputElement;
      if (target.files?.length) {
        const files = Array.from(target.files) as FileWithPath[];
        await handleFileSelected(files, []);
      }
    };

    // Trigger file selector
    input.click();
  };

  const onRemoveResume = () => {
    uiStore.setUserCv({
      ...profile.userCV,
      downloadUrl: undefined,
    });
    profile.removeUserCv();
  };

  const onUpdateUploadedAt = () => {
    if (profile.userCV.downloadUrl) {
      handleUpload(profile.userCV.downloadUrl);
      setIsUpToDateConfirmation(true);
      setShowSuccessMessage(true);
    }
  };

  const lastUpdatedFormatted = lastUpdated
    ? format(new Date(lastUpdated), 'MMM d, yyyy')
    : undefined;

  return (
    <Section
      type={'large'}
      title={
        <div>
          Resume <span className={styles.optional}>(Optional)</span>{' '}
        </div>
      }
    >
      {profile.userCV.downloadUrl ? (
        <>
          {lastUpdated && (
            <div className={styles.description}>
              {isResumeOlderThanThreeMonths
                ? 'Your resume was last updated over 3 months ago. Is your resume up to date?'
                : `Last uploaded: ${lastUpdatedFormatted}`}
            </div>
          )}
          <ResumeItem
            uploading={uploading}
            mostRecentExperience={lastResumeParsed?.mostRecentExperience}
            isResumeOlderThanThreeMonths={isResumeOlderThanThreeMonths}
            showSuccessMessage={showSuccessMessage}
            resumeLabel={profile.userCV.label}
            resumeDownloadUrl={profile.userCV.downloadUrl}
            onUpdateResume={handleResumeUpload}
            onRemoveResume={onRemoveResume}
            onUpdateUploadedAt={onUpdateUploadedAt}
            isUpToDateConfirmation={isUpToDateConfirmation}
          />
        </>
      ) : (
        <>
          <div className={styles.description}>
            Proposed candidates with a resume have a higher likelihood of being
            selected for an interview. Upload a resume to stand out on
            proposals.
          </div>
          <Button
            size="sm"
            variant={'secondary'}
            className={styles.button}
            loading={!!uploading}
            onClick={handleResumeUpload}
          >
            <Icon name="upload" size="sm" />
            {uploading ? 'Uploading...' : 'Upload Resume'}
          </Button>
        </>
      )}

      <LoadingIndicator
        successMsg={'Your resume has been uploaded successfully.'}
        loading={uploading}
      />
    </Section>
  );
};

export default observer(Resume);
